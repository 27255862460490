import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const columns = [
  {
    id: "id",
    label: "ID",
    minWidth: 10,
    format: (value) => {
      return value;
    },
  },
  {
    id: "username",
    label: "User Name",
    minWidth: 30,
    format: (value) => {
      return value;
    },
  },
  {
    id: "role",
    label: "Role",
    minWidth: 30,
    format: (value) => {
      return value;
    },
  },
  {
    id: "saleCount",
    label: "Agent Sales",
    minWidth: 30,
    format: (value) => {
      return value;
    },
  },
  {
    id: "count",
    label: "Today Sales",
    minWidth: 30,
    format: (value) => {
      return value;
    },
  },

];

export default function UserTable({
  data,
  totalPage,
  setPage,
  setLimit,
  limit,
  page,
  onRowClick,
}) {
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{
          height: "70vh ",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => {
                return (
                  <TableRow
                    onClick={() => onRowClick(row)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          { column.format(value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        count={totalPage}
        rowsPerPage={limit}
        page={page}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
