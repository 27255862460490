import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const columns = [
  { id: "id", label: "ID", minWidth: 10 },
  {
    id: "firstname",
    label: "First Name",
    minWidth: 30,
  },
  {
    id: "lastname",
    label: "Last Name",
    minWidth: 30,
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 100,
  },
  {
    id: "state",
    label: "State",
    width: 30,
  },
  {
    id: "city",
    label: "City",
    minWidth: 100,
  },
  {
    id: "zip",
    label: "Zip",
    minWidth: 70,
  },
  {
    id: "dob",
    label: "DOB",
    width: 40,
  },
  {
    id: "saletype",
    label: "Sale Type",
    width: 40,
  },
  {
    id: "saleAgent",
    label: "Agent",
    width: 40,
  },
];

export default function SaleTabel({
  data,
  totalPage,
  setPage,
  setLimit,
  limit,
  page,
}) {
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{
          height: "70vh ",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        count={totalPage}
        rowsPerPage={limit}
        page={page}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
