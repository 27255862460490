import React from "react";
import load from "./../../assets/Loader.svg";

function Loader() {
  return (
    <div className="spinner-div">
      <img src={load} alt="loader" />
    </div>
  );
}

export default Loader;
