import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import {
  AiFillAccountBook,
  AiFillPhone,
  AiFillSetting,
  AiOutlineArrowDown,
  AiOutlineDatabase,
  AiOutlineLogout,
  AiOutlineSearch,
} from "react-icons/ai";
import logoImage from "../assets/images/logo.png";
import { AirlineSeatLegroomNormalSharp } from "@mui/icons-material";

function PageLayout({ children }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const scrollRef = useRef(null);
  const options =
    user?.role === "Admin"
      ? [
          {
            id: 1,
            title: "Dashboard",
            icon: <HiMenu />,
            link: "/dashboard",
          },
          {
            id: 2,
            title: "Data",
            icon: <AiFillAccountBook />,
            link: "/data",
          },
          {
            id: 3,
            title: "Search Data",
            icon: <AiOutlineSearch />,
            link: "/search-data",
          },
          {
            id: 8,
            title: "Search Numbers",
            icon: <AiFillPhone />,
            link: "/search-numbers",
          },
          // {
          //   id: 4,
          //   title: "Dumb Data",
          //   icon: <AiOutlineDatabase />,
          //   link: "/dumb-data",
          // },
          {
            id: 7,
            title: "Sales",
            icon: <AiOutlineArrowDown />,
            link: "/sales",
          },
          // {
          //   id: 5,
          //   title: "Compains",
          //   icon: <AiOutlineDatabase />,
          //   link: "/compains",
          // },
          {
            id: 6,
            title: "Employee",
            icon: <AirlineSeatLegroomNormalSharp />,
            link: "/employee",
          },
        ]
      : user?.role === "Customer"
      ? [
          {
            id: 7,
            title: "Sales",
            icon: <AiOutlineArrowDown />,
            link: "/sales",
          },
          {
            id: 8,
            title: "Search Numbers",
            icon: <AiFillPhone />,
            link: "/search-numbers",
          },
          {
            id: 3,
            title: "Search Data",
            icon: <AiOutlineSearch />,
            link: "/search-data",
          },
        ]
      : [
          {
            id: 7,
            title: "Sales",
            icon: <AiOutlineArrowDown />,
            link: "/sales",
          },
        ];

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      {/* <div className="flex items-center justify-between p-4 h-[3rem] w-full border-b-[1px]">
        <img src={logoImage} className="w-[10rem] " alt="logo" />

        <div className="flex items-center justify-center">
          <img
            src="https://images.unsplash.com/photo-1541560052-5e137f229371?auto=format&fit=crop&q=80&w=3087&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="w-[2rem] h-[2rem] rounded-full mr-2"
            alt="profile"
          />
          <div>
            <label className="font-medium">Admin</label>
            <p className="text-sm font-regular mt-[-8px] text-slate-400">
              Administrator
            </p>
          </div>
        </div>
      </div> */}

      <div className="flex ">
        <div className="hidden sm:flex bg-[#0d0d0d] text-white flex-col justify-between cursor-pointer  h-screen  p-4 md:w-[14rem] border-b-[1px]">
          <div>
            <div
              className=""
              onClick={() => {
                window.location.replace("/");
              }}
            >
              <img src={logoImage} className="w-[13rem] " alt="logo" />
            </div>
            <div className="flex w-full mt-[2rem] flex-col gap-4">
              {options.map((item) => (
                <NavLink
                  className="flex w-full text-[0.9rem] items-center hover:bg-[#242424] rounded-md  gap-2  px-3 py-2"
                  to={item.link}
                  key={item.id}
                  activeClassName="flex w-full items-center hover:bg-[#21242e]  gap-2 rounded-md px-3 py-2 bg-[#21242e] text-white"
                >
                  {item.icon}
                  {item.title}
                </NavLink>
              ))}
            </div>
          </div>
          <div
            className="flex items-center hover:border-white border-[#16181f] hover:text-red-600 border-[.02rem] rounded-md px-3 w-full gap-2 h-[3rem]"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            <AiOutlineLogout />
            Logout
          </div>
        </div>

        <div className="p-4 flex-1 h-full overflow-hidden " ref={scrollRef}>
          {children}
          <footer className="fixed z-1 bottom-0 w-full bg-white">
            <p className="text-center text-sm text-slate-400 pb-5">
              © {new Date().getFullYear()} All Rights Reserved.
              <b className="text-primary font-semibold"> Select Pakistan</b>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default PageLayout;
