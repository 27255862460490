import React from "react";

function DataTile({ user }) {
  return (
    <section className="w-full  shadow-md bg-white rounded-md overflow-hidden mb-3">
      <div className="flex items-center justify-between px-3 bg-primary h-[4rem] w-full text-white ">
        <div>
          <label className="font-regular text-sm">PERSON NAME</label>
          <h2 className="font-bold text-lg">{user.name}</h2>
        </div>
        <div>
          <label className="font-regular text-sm">PHONE</label>
          <h2 className="font-bold text-lg">{user.phone}</h2>
        </div>
        <div>
          <h2>AGE</h2>
          {user.age}
        </div>
      </div>

      {user.addresses.map((item, index) => (
        <div
          key={index}
          className="grid grid-cols-9 items-center border-b-[1px] border-dashed justify-between px-3 bg-white h-[4rem] w-full"
        >
          <div className="col-span-2">
            <label className="font-regular text-sm">HOME</label>
            <h2 className="font-medium text-md"> {item.home}</h2>
          </div>

          <div className="col-span-2">
            <label className="font-regular text-sm">CITY</label>
            <h2 className="font-medium text-md"> {item.city}</h2>
          </div>
          <div className="col-span-2">
            <label className="font-regular text-sm">STATE</label>
            <h2 className="font-medium text-md"> {item.state}</h2>
          </div>
          <div className="col-span-2">
            <label className="font-regular text-sm">ZIP</label>
            <h2 className="font-medium text-md"> {item.zip}</h2>
          </div>
          <div className="col-span-1 justify-end flex text-[1.5rem] font-bold text-primary">
            {item.isDeliverable}
          </div>
        </div>
      ))}

      <div className="flex items-center justify-between px-3 bg-white h-[4rem] w-full ">
        <div>
          <h2>RELATED</h2>
          <div className="flex gap-2 text-gray-500 ">
            {user.relatives.join(", ")}
          </div>
        </div>
        <div>
          <h2>DOB</h2>
          {user.dob}
        </div>
      </div>
    </section>
  );
}

export default DataTile;
