import React from "react";
import Select from "react-select";

function Filters({
  onApplyFilters,
  onClose,
  selectedFilters,
  setSelectedFilters,
}) {
  
  const orderByOptions = [
    { value: "ASC", label: "ASCENDING" },
    { value: "DESC", label: "DESCENDING" },
  ];
  const limitOptions = [
    { value: 100, label: "100 Rows" },
    { value: 500, label: "500 Rows" },
    { value: 1000, label: "1000 Rows" },
    { value: 2000, label: "2000 Rows" },
    { value: 10000, label: "10000 Rows" },
    // { value: 50000, label: "50000 Rows" },
    // { value: 100000, label: "100000 Rows" },
  ];

  return (
    <section>
      <div className="flex items-center justify-between">
        <h1>Filters</h1>
        <div>
          <button
            className="px-4 py-2  rounded-md bg-primary border-[1px] border-primary text-white mr-4 "
            onClick={() => {
              onApplyFilters();
            }}
          >
            Apply
          </button>
          <button
            className="px-4 py-2  rounded-md bg-[#fefefe] border-[1px] border-primary text-primary "
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>

      <div className="flex items-center justify-start gap-5 flex-wrap w-full">
        <input
          className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="text"
          placeholder="First Name"
          value={selectedFilters.firstname}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              firstname: e.target.value,
            })
          }
        />
        <input
          className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="text"
          placeholder="Last Name"
          value={selectedFilters.lastname}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              lastname: e.target.value,
            })
          }
        />
        <input
          className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="text"
          placeholder="State"
          value={selectedFilters.state}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              state: e.target.value.toUpperCase(),
            })
          }
        />
        <input
          className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="text"
          placeholder="City"
          value={selectedFilters.city}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              city: e.target.value,
            })
          }
        />
        <input
          className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="number"
          placeholder="DOB Year"
          value={selectedFilters.dob_y}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              dob_y: e.target.value,
            })
          }
        />
        <input
          className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="number"
          placeholder="Enter Zip Code"
          value={selectedFilters.zip}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              zip: e.target.value,
            })
          }
        />

        <Select
          value={limitOptions.find(
            (option) => option.value === selectedFilters.limit
          )}
          onChange={(selectedOption) =>
            setSelectedFilters({
              ...selectedFilters,
              limit: selectedOption.value,
            })
          }
          options={limitOptions}
          placeholder="Set Limits"
          className="text-sm w-[14rem] outline-none mt-4"
        />
        <Select
          value={orderByOptions.find(
            (option) => option.value === selectedFilters.orderBy
          )}
          onChange={(selectedOption) =>
            setSelectedFilters({
              ...selectedFilters,
              orderBy: selectedOption.value,
            })
          }
          options={orderByOptions}
          placeholder="Set Order By"
          className="text-sm w-[14rem] outline-none mt-4"
        />
      </div>
    </section>
  );
}

export default Filters;
