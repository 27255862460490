import RouterComponent from "./Router/Router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  const primaryColor = localStorage.getItem("primaryColor")
    ? localStorage.getItem("primaryColor")
    : "#0070f3";
  return (
    <div style={{ "--primary-color": primaryColor }}>
      <RouterComponent />
      <ToastContainer
        autoClose={1000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
        hideProgressBar
        closeButton={false}
      />

      
    </div>
  );
}

export default App;
