import React, { useState, useEffect } from "react";
import Loader from "../../../Components/Loader/Loader";
import { AiFillSave, AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import axios from "axios";
import { BASE_URL, API_ENDPOINT } from "../../../Config/Config";
import { toast } from "react-toastify";
import SaleTabel from "../../../Components/SaleTabel/SaleTabel";

function Sales() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [loader, setLoading] = useState(false);
  const [isShowNewSaleDialog, setIsShowNewSaleDialog] = useState(false);
  const [allSale, setAllSales] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalPage, setTotalPages] = useState(1);

  useEffect(() => {
    fetchSales();
  }, [page]);

  const fetchSales = async () => {
    try {
      const url =
        BASE_URL +
        API_ENDPOINT.GET_ALL_SALE +
        `?role=${user.role}&agentName=${user.username}&page=${
          page + 1
        }&limit=${limit}`;

      setLoading(true);
      const response = await axios.get(url);
      setLoading(false);
      if (response.data.success) {
        setAllSales(response.data.response);
        setTotalPages(response.data.total_count);
      } else {
        console.log(response.data.message);
        toast.dark("Hey 👋, No data found with given filter");
        setAllSales([]);
      }
    } catch (e) {
      setLoading(false);
      toast.dark(e);
    }
  };

  const [saleFormFields, setSaleFormFields] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    state: "",
    city: "",
    zip: "",
    age: "",
    dob: "",
    saletype: "Connected",
  });

  const options = [
    {
      label: "Sale Made",
      value: "Sale Mad",
    },
    {
      label: "Call Back",
      value: "Call Back",
    },
    {
      label: "Connected",
      value: "Connected",
    },
  ];

  const onValidateFields = () => {
    console.log(saleFormFields);
    if (
      !(
        saleFormFields.firstname &&
        saleFormFields.lastname &&
        saleFormFields.phone &&
        saleFormFields.state &&
        saleFormFields.city &&
        saleFormFields.zip &&
        saleFormFields.dob &&
        saleFormFields.saletype
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleOnSave = async () => {
    console.log("saleFormFields.saletype", saleFormFields.saletype);
    console.log(" saleFormFields.phone ", saleFormFields.phone);
    try {
      if (
        saleFormFields.saletype == "Connected" &&
        saleFormFields.phone !== ""
      ) {
        setLoading(true);

        const url = BASE_URL + API_ENDPOINT.CREATE_SALE;
        const body = {
          firstname: "Temp",
          lastname: "Temp",
          phone: saleFormFields.phone,
          state: "Temp",
          city: "Temp",
          zip: "Temp",
          dob: "Temp",
          saletype: "Connected",
          address: "Temp",
          saleAgent: user.username,
        };
        const response = await axios.post(url, body);
        if (response.data.success) {
          toast.success("Sale created successfully");
        }
        console.log(response);
        setLoading(false);
        setSaleFormFields({
          phone: "",
          saletype: "Connected",
        });
      } else if (onValidateFields()) {
        setLoading(true);

        const url = BASE_URL + API_ENDPOINT.CREATE_SALE;
        const body = {
          firstname: saleFormFields.firstname,
          lastname: saleFormFields.lastname,
          phone: saleFormFields.phone,
          state: saleFormFields.state,
          city: saleFormFields.city,
          zip: saleFormFields.zip,
          dob: saleFormFields.dob,
          saletype: saleFormFields.saletype,
          address: saleFormFields.address,
          saleAgent: user.username,
        };
        const response = await axios.post(url, body);
        if (response.data.success) {
          toast.success("Sale created successfully");
        }
        console.log(response);
        setLoading(false);
        setSaleFormFields({
          firstname: "",
          lastname: "",
          phone: "",
          state: "",
          city: "",
          zip: "",
          age: "",
          dob: "",
          address: "",
          saletype: "",
        });
      } else {
        toast.dark("Hey 👋, Please fill all the fields");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.dark(e.message);
    }
  };

  return (
    <section className="h-[96vh] w-full scrollbar-none">
      {loader && <Loader />}
      {isShowNewSaleDialog && (
        <section
          className="flex items-center justify-center h-screen w-screen fixed z-10 top-0 right-0"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="w-[80%] lg:w-[56%] min-h-[40%] bg-white rounded-lg p-10 ">
            <h2>Add User Information</h2>
            <div className="flex items-start justify-start gap-10 flex-wrap">
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="First Name"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    firstname: e.target.value,
                  })
                }
                value={saleFormFields.firstname}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="Last Name"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    lastname: e.target.value,
                  })
                }
                value={saleFormFields.lastname}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="number"
                placeholder="DOB"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    dob: e.target.value,
                  })
                }
                value={saleFormFields.dob}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="Address"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    address: e.target.value,
                  })
                }
                value={saleFormFields.address}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="City"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    city: e.target.value,
                  })
                }
                value={saleFormFields.city}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="number"
                placeholder="Zip"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    zip: e.target.value,
                  })
                }
                value={saleFormFields.zip}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="State"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    state: e.target.value,
                  })
                }
                value={saleFormFields.state}
              />
              <input
                className="text-sm w-[14rem] outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="number"
                placeholder="Phone"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    phone: e.target.value,
                  })
                }
                value={saleFormFields.phone}
              />
              <Select
                options={options}
                placeholder="Set Sale Type"
                className="text-sm w-[14rem] outline-none mt-4"
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  setSaleFormFields({
                    ...saleFormFields,
                    saletype: selectedOption?.value,
                  });
                }}
                value={options.find(
                  (option) => option.value === saleFormFields.saletype
                )} // Match the selected option by value
              />
            </div>
            <div className=" mt-10 w-full flex items-center justify-end gap-7">
              <button
                className="px-4 py-2  flex gap-1 items-center rounded-md border-[1px] border-primary bg-white text-primary "
                onClick={() => {
                  setIsShowNewSaleDialog(!isShowNewSaleDialog);
                  fetchSales();
                }}
              >
                <AiOutlineClose />
                Close
              </button>{" "}
              <button
                className="px-4 py-2 flex items-center gap-1  rounded-md border-[1px] border-primary bg-primary text-white "
                onClick={handleOnSave}
              >
                <AiFillSave />
                Save
              </button>
            </div>
          </div>
        </section>
      )}

      <div className="w-full p-4 flex items-center justify-between">
        <h2>Sales</h2>
        <button
          className="px-4 py-2  rounded-md border-[1px] border-primary bg-primary text-white "
          onClick={() => setIsShowNewSaleDialog(!isShowNewSaleDialog)}
        >
          Add New Sales
        </button>
      </div>

      {allSale && (
        <SaleTabel
          data={allSale}
          setPage={setPage}
          setLimit={setLimit}
          totalPage={totalPage}
          limit={limit}
          page={page}
        />
      )}
    </section>
  );
}

export default Sales;
