import React, { useState, useEffect } from "react";
import {

  AiOutlineCloudUpload,
} from "react-icons/ai";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import { BASE_URL, API_ENDPOINT } from "../../../Config/Config";

function DumbData() {
  const [isFileValid, setIsFileValid] = useState(true);
  const inputRef = React.createRef();
  const [selectedFile, setSelecteFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFilesData();
  }, []);

  const getFilesData = async () => {
    try {
      setLoading(true);
      const url = BASE_URL + API_ENDPOINT.GET_FILES;
      const response = await axios.get(url);
      setLoading(false);
      if (response.data.success) {
        console.log(response.data.result);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".csv")) {
      setIsFileValid(true);
      setSelecteFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setIsFileValid(false);
    }
  };

  const onUploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("fileName", fileName);
      setLoading(true);
      const url = BASE_URL + API_ENDPOINT.UPLOAD_FILE;
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      setSelecteFile(null);
      getFilesData();
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleTextClick = () => {
    inputRef.current.click();
  };
  return (
    <section className="h-[80vh] w-full scrollbar-none">
      {loading && <Loader />}
      <div className="w-full p-4 flex items-center justify-between">
        <h2>Upload File With Data</h2>
        <button
          className="px-4 py-2  rounded-md border-[1px] border-primary bg-primary text-white "
          onClick={onUploadFile}
        >
          Upload File
        </button>
      </div>
      <div className="bg-white w-screen h-[60vh] flex items-center justify-center">
        <div className="flex p-4 flex-col bg-white cursor-pointer w-[80%] lg:w-[40vw] h-[28vh] items-center rounded-lg ">
          <div className="flex border-2 rounded-md bg-white cursor-pointer w-full h-full  items-center justify-center flex-col text-lg font-medium family-poppins text-primary">
            <div
              className="flex flex-col cursor-pointer items-center justify-center"
              onClick={handleTextClick}
            >
              <AiOutlineCloudUpload className="text-5xl text-primary" />
              {isFileValid ? (
                selectedFile ? (
                  <p>{selectedFile.name}</p>
                ) : (
                  <p>Upload .csv file Here</p>
                )
              ) : (
                "Please select a valid .csv file"
              )}
            </div>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              ref={inputRef}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DumbData;
