import React, { useState, useEffect } from "react";
import Loader from "../../../Components/Loader/Loader";
import { AiFillDelete, AiFillSave, AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import axios from "axios";
import { BASE_URL, API_ENDPOINT } from "../../../Config/Config";
import { toast } from "react-toastify";
import UserTable from "../../../Components/UserTable/UserTable";
function Employee() {
  const [loader, setLoading] = useState(false);
  const [isShowNewSaleDialog, setIsShowNewSaleDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalPage, setTotalPages] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);

  const [saleFormFields, setSaleFormFields] = useState({
    username: "",
    password: "",
    role: "",
  });

  const options = [
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Dailer",
      value: "Dailer",
    },
    {
      label: "Customer",
      value: "Customer",
    },
  ];

  useEffect(() => {
    fetchUsers();
  }, [page]);

  const fetchUsers = async () => {
    try {
      const url = BASE_URL + API_ENDPOINT.USERS.GET_USERS;
      setLoading(true);
      const response = await axios.get(url);
      setLoading(false);
      if (response.data.success) {
        let tempUser = [];
        response.data.response.map((user) => {
          let found = false;
          response.data.queryCount.map((count) => {
            if (count.id === user.id) {
              found = true;
              tempUser.push({
                ...user,
                count: count.todayCount,
              });
            }
          });
          if (!found) {
            tempUser.push({
              ...user,
              count: 0,
            });
          }
        });
        console.log(tempUser);
        setUsers(tempUser);
      } else {
        console.log(response.data.message);
        toast.dark("Hey 👋, No data found with given filter");
        setAllSales([]);
      }
    } catch (e) {
      setLoading(false);
      toast.dark(e);
    }
  };

  const onValidateFields = () => {
    if (
      !(
        saleFormFields.username &&
        saleFormFields.password &&
        saleFormFields.role
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleOnSave = async () => {
    try {
      if (onValidateFields()) {
        setLoading(true);

        const url = BASE_URL + API_ENDPOINT.USERS.CREATE_USER;
        const body = {
          username: saleFormFields.username,
          password: saleFormFields.password,
          role: saleFormFields.role,
        };
        const response = await axios.post(url, body);
        if (response.data.success) {
          toast.success("Users created successfully");
          setIsShowNewSaleDialog(false);
          setSaleFormFields({
            username: "",
            password: "",
            role: "",
          });
          fetchUsers();
        }
        console.log(response);
        setLoading(false);
      } else {
        toast.dark("Hey 👋, Please fill all the fields");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.dark(e.message);
    }
  };

  const handleUpdateUser = async () => {};

  const onRowClick = (row) => {
    setSelectedRow(row);
    setShowEditUserDialog(true);
  };

  return (
    <section className="h-[96vh] w-full scrollbar-none">
      {loader && <Loader />}
      {isShowNewSaleDialog && (
        <section
          className="flex items-center justify-center h-screen w-screen fixed z-10 top-0 right-0"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="w-[80%] lg:w-[56%] min-h-[40%] bg-white rounded-lg p-10 ">
            <h2>Add User Information</h2>
            <div className="flex items-start justify-start gap-10 flex-wrap">
              <input
                className="text-sm w-full outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="User Name"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    username: e.target.value,
                  })
                }
                value={saleFormFields.username}
              />
              <input
                className="text-sm w-full outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="password"
                placeholder="Password"
                onChange={(e) =>
                  setSaleFormFields({
                    ...saleFormFields,
                    password: e.target.value,
                  })
                }
                value={saleFormFields.password}
              />

              <Select
                options={options}
                placeholder="Select Role"
                className="text-sm w-full outline-none mt-4"
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  setSaleFormFields({
                    ...saleFormFields,
                    role: selectedOption?.value,
                  });
                }}
                value={options.find(
                  (option) => option.value === saleFormFields.role
                )} // Match the selected option by value
              />
            </div>
            <div className=" mt-10 w-full flex items-center justify-end gap-7">
              <button
                className="px-4 py-2  flex gap-1 items-center rounded-md border-[1px] border-primary bg-white text-primary "
                onClick={() => setIsShowNewSaleDialog(!isShowNewSaleDialog)}
              >
                <AiOutlineClose />
                Close
              </button>{" "}
              <button
                className="px-4 py-2 flex items-center gap-1  rounded-md border-[1px] border-primary bg-primary text-white "
                onClick={handleOnSave}
              >
                <AiFillSave />
                Save
              </button>
            </div>
          </div>
        </section>
      )}

      {showEditUserDialog && (
        <section
          className="flex items-center justify-center h-screen w-screen fixed z-10 top-0 right-0"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="w-[80%] lg:w-[56%] min-h-[40%] bg-white rounded-lg p-10 ">
            <h2>Update User Information</h2>
            <div className="flex items-start justify-start gap-10 flex-wrap">
              <input
                className="text-sm w-full outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="text"
                placeholder="User Name"
                onChange={(e) =>
                  setSaleFormFields({
                    ...selectedRow,
                    username: e.target.value,
                  })
                }
                value={selectedRow.username}
              />
              <input
                className="text-sm w-full outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                type="password"
                placeholder="Password"
                onChange={(e) =>
                  setSaleFormFields({
                    ...selectedRow,
                    password: e.target.value,
                  })
                }
                value={selectedRow.password}
              />

              <Select
                options={options}
                placeholder="Select Role"
                className="text-sm w-full outline-none mt-4"
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  setSaleFormFields({
                    ...selectedRow,
                    role: selectedRow?.value,
                  });
                }}
                value={options.find(
                  (option) => option.value === selectedRow.role
                )} // Match the selected option by value
              />
            </div>
            <div className=" mt-10 w-full flex items-center justify-end gap-7">
              <button
                className="px-4 py-2  flex gap-1 items-center rounded-md border-[1px] border-primary bg-white text-primary "
                onClick={() => setShowEditUserDialog(!showEditUserDialog)}
              >
                <AiOutlineClose />
                Close
              </button>
              <button
                className="px-4 py-2  flex gap-1 items-center rounded-md  bg-red-600 text-white "
                onClick={() => setShowEditUserDialog(!showEditUserDialog)}
              >
                <AiFillDelete />
                Delete
              </button>
              <button
                className="px-4 py-2 flex items-center gap-1  rounded-md border-[1px] border-primary bg-primary text-white "
                onClick={handleUpdateUser}
              >
                <AiFillSave />
                Update
              </button>
            </div>
          </div>
        </section>
      )}

      <div className="w-full p-4 flex items-center justify-between">
        <h2>Employeees</h2>
        <button
          className="px-4 py-2  rounded-md border-[1px] border-primary bg-primary text-white "
          onClick={() => setIsShowNewSaleDialog(!isShowNewSaleDialog)}
        >
          Add New Employe
        </button>
      </div>

      {users && (
        <UserTable
          data={users}
          setPage={setPage}
          setLimit={setLimit}
          totalPage={totalPage}
          limit={limit}
          page={page}
          onRowClick={(row) => onRowClick(row)}
        />
      )}
    </section>
  );
}

export default Employee;
