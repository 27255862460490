import React, { useState } from "react";
import {
  AiFillCloseCircle,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import DataTile from "../../../Components/DataTile/DataTile";
import Papa from "papaparse";

function SearchNumber() {
  const [csvData, setCSVData] = useState([]);
  const [isFileValid, setIsFileValid] = useState(true);
  const inputRef = React.createRef();
  const [selectedFile, setSelecteFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchNumber, setSearchNumber] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".csv")) {
      setIsFileValid(true);
      convertFileToDataSet(e.target.files[0]);
      setFileName(selectedFile.name);
    } else {
      setIsFileValid(false);
    }
  };

  const convertFileToDataSet = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        console.log(result.data);
        // Extract data from the CSV file
        const data = result.data.map((row) => {
          return Object.values(row).toString();
        }); // Assuming you want the first column
        console.log(data);

        setCSVData(data);
      },
      header: true, // Set to true if your CSV has headers
    });
  };

  const onCloseDialog = () => {
    setIsShowDialog(false);
    setSelecteFile(null);
    setFileName("");
  };

  const handleTextClick = () => {
    inputRef.current.click();
  };

  const SearchDataFromUSPeople = async () => {
    try {
      setLoading(true);
      const url = `https://hvyrsaj0of.execute-api.us-east-2.amazonaws.com/person?x=${searchNumber}`;
      const rsp = await axios.get(url);
      setLoading(false);
      if (rsp.data.status !== "invalid") {
        console.log(rsp.data.person);
        setSearchResult(rsp.data.person);
      } else {
        toast.dark("Please enter a valid number");
        setSearchResult([]);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const SearchDataFromUSPeopleBulk = async () => {
    try {
      if (csvData.length === 0) {
        toast.error("Please select a file");
        return;
      } else {
        if (csvData.length < 1000) {
          setLoading(true);
          const requests = [];

          csvData.forEach((item) => {
            if (item !== "") {
              requests.push(
                axios.get(
                  `https://hvyrsaj0of.execute-api.us-east-2.amazonaws.com/person?x=${item}`
                )
              );
            }
          });

          const rsp = await Promise.all(requests);
          console.log("rsp=>", rsp);

          // Filter responses with invalid data
          const validResponses = rsp.filter(
            (item) => item.data.status !== "invalid" && item.data !== undefined
          );

          let myData = [];
          setLoading(false);

          validResponses.forEach((item, index) => {
            console.log("index=>", index, "=>", item.data);
            if (item.data.status !== "invalid" && item.data.status !== "err") {
              const tempObj = [];
              item.data.person.map((i) => {
                tempObj.push({
                  ...i,
                  phone: item.data.phone,
                });
              });

              console.log("tempObj=>", tempObj);

              myData = [...myData, ...tempObj];
            }
            console.log("----------------------------");
            console.log("MY DATA=>", myData);
          });

          if (myData.length > 0) {
            // setSearchResult(myData);
            onExportCSV(myData);
            setIsShowDialog(false);
            setSelecteFile(null);
            setFileName("");
          } else {
            toast.dark("Please select a file with valid records");
          }
        } else {
          toast.dark("Please select a file less than 100 records");
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      setIsShowDialog(false);
      setSelecteFile(null);
      setFileName("");
    }
  };

  const onExportCSV = async (data) => {
    // Columns to include in the CSV
    const selectedColumns = ["name", "status", "dob", "age", "state", "phone"];

    // Extract only the selected columns from the data
    const filteredData = data.map((item) => {
      const filteredItem = {};
      selectedColumns.forEach((column) => {
        if (column === "state") {
          // Handle cases where 'addresses' or 'addresses[0]' might be undefined or empty
          filteredItem[column] =
            item.addresses && item.addresses.length > 0
              ? item.addresses[0].state || ""
              : "";
        } else {
          filteredItem[column] = item[column];
        }
      });
      return filteredItem;
    });

    // Convert the data to a CSV string
    const csvHeader = selectedColumns
      .map((column) => `\`${column}\``)
      .join(",");
    const csv =
      csvHeader +
      "\n" +
      filteredData
        .map((item) =>
          selectedColumns.map((column) => item[column] ?? "").join(",")
        )
        .join("\n");

    // Create a Blob containing the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link for the CSV file
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      SearchDataFromUSPeople();
    }
  };

  return (
    <section className="h-[90vh] w-full  scrollbar-none overflow-scroll">
      {loading && <Loader />}
      {isShowDialog && (
        <div
          className="bg-white w-screen h-screen flex items-center justify-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 10,
          }}
        >
          <div className="flex p-4 flex-col bg-white cursor-pointer w-[40vw] h-[28vh] items-center rounded-lg ">
            <div
              className=" w-full flex items-end justify-end"
              onClick={onCloseDialog}
            >
              <AiFillCloseCircle size={24} />
            </div>

            <div className="flex bg-white cursor-pointer w-full h-full  items-center justify-center flex-col text-lg font-medium family-poppins text-primary">
              <div
                className="flex flex-col cursor-pointer items-center justify-center"
                onClick={handleTextClick}
              >
                <AiOutlineCloudUpload className="text-5xl text-primary" />
                {isFileValid ? (
                  selectedFile ? (
                    <p>{selectedFile.name}</p>
                  ) : (
                    <p>Upload .csv file Here</p>
                  )
                ) : (
                  "Please select a valid .csv file"
                )}
              </div>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={inputRef}
                style={{ display: "none" }}
              />

              <button
                onClick={SearchDataFromUSPeopleBulk}
                className="bg-primary text-white px-4 py-2 rounded-md"
              >
                Start Search
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="w-[83.4vw] pb-2 mb-2 pt-2 fixed bg-white flex items-center justify-between">
        <h2>Search Numbers</h2>

        <input
          type="number"
          maxLength={10}
          className="text-sm w-1/2 outline-none px-4 py-2 border border-solid border-gray-300 rounded "
          placeholder="Enter Number"
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            setSearchNumber(e.target.value);
          }}
        />
        <button
          className="px-4 py-2  rounded-md border-[1px] border-primary bg-primary text-white "
          onClick={() => setIsShowDialog(true)}
        >
          Upload File
        </button>
      </div>

      <div className="mt-[6rem]">
        {searchResult.length > 0 &&
          searchResult.map((item, index) => (
            <DataTile key={index} user={item} />
          ))}
      </div>
    </section>
  );
}

export default SearchNumber;
