import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { API_ENDPOINT, BASE_URL } from "../../../Config/Config";

import logo from "./../../../assets/images/logo.png";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import { toast } from "react-toastify";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Function to handle password input change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Function to handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission action
    try {
      setLoading(true);
      const response = await axios.post(BASE_URL + API_ENDPOINT.USERS.SIGN_IN, {
        username: email,
        password,
      });
      setLoading(false);
      console.log(response.data);
      if (response.data.success) {
        localStorage.setItem("user", JSON.stringify(response.data.response));
        localStorage.setItem("isLoggedIn", true);
        if (response.data.response.role === "Admin")
          props.history.push("/dashboard");
        else {
          props.history.push("/sales");
        }
      } else {
        console.log(response.data.message);
        setLoading(false);
        toast.dark(response.data.message);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.dark(e.response.data.message);
    }
  };

  return (
    <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
      {loading && <Loader />}
      <div className="md:w-1/3 max-w-sm">
        <img
          src="https://img.freepik.com/free-vector/mobile-login-concept-illustration_114360-83.jpg?w=1380&t=st=1697812925~exp=1697813525~hmac=acd90d4a15cdff07c80185e1b307eda4274deeada514e2ac68915a8eebaee727"
          alt="Sample image"
        />
      </div>
      <div className="md:w-1/3 max-w-sm ">
        <div className="w-full flex justify-center mb-8">
          <img src={logo} alt="Logo" />
        </div>
        <form onSubmit={handleFormSubmit}>
          <input
            className="text-sm w-full outline-none px-4 py-2 border border-solid border-gray-300 rounded"
            type="text"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange} // Handle email input change
          />
          <input
            className="text-sm w-full outline-none px-4 py-2 border border-solid border-gray-300 rounded mt-4"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange} // Handle password input change
          />
          <div className="mt-4 flex justify-between font-semibold text-sm">
            <label className="flex text-slate-500 hover:text-slate-600 cursor-pointer">
              <input className="mr-1" type="checkbox" />
              <span>Remember Me</span>
            </label>
            <a
              className="text-primary hover:text-blue-700 hover:underline hover:underline-offset-4"
              href="#"
            >
              Forgot Password?
            </a>
          </div>
          <div className="text-center md:text-left">
            <button
              className="mt-4 w-full bg-primary hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider font-[poppins-medium]"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
        <div className="mt-4 w-full justify-center flex font-semibold text-sm text-slate-500 text-center md:text-left">
          Don't have an account?{" "}
          <a
            className="text-red-600 ml-2 hover:underline hover:underline-offset-4"
            href="#"
          >
            Register
          </a>
        </div>
      </div>
      <footer className="fixed z-1 bottom-0 w-full bg-white">
        <p className="text-center text-sm text-slate-400 pb-5">
          © {new Date().getFullYear()} All Rights Reserved.
          <b className="text-primary font-semibold"> Select Pakistan</b>
        </p>
      </footer>
    </section>
  );
}

export default withRouter(Login);
