// router.js

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import PageLayout from "./PageLayout";
import Login from "../Pages/Auth/Login/Login";
import Dashboard from "../Pages/App/Dashboard/Dashboard";
import Users from "../Pages/App/Users/Users";
import UploadData from "../Pages/App/UploadData/UploadData";
import Employee from "../Pages/App/Employee/Employee";
import DumbData from "../Pages/App/DumbData/DumbData";
import PrivateRoute from "./PrivateRoute";
import Sales from "../Pages/App/Sales/Sales";
import SearchNumber from "../Pages/App/SearchNumber/SearchNumber";

const RouterComponent = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <PageLayout>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/data" component={Users} />
          <PrivateRoute exact path="/search-data" component={UploadData} />
          <PrivateRoute exact path="/employee" component={Employee} />
          <PrivateRoute exact path="/dumb-data" component={DumbData} />
          <PrivateRoute exact path="/sales" component={Sales} />
          <PrivateRoute exact path="/search-numbers" component={SearchNumber} />
        </PageLayout>
      </Switch>
    </Router>
  );
};

export default RouterComponent;
