import React, { useEffect, useState } from "react";
import TableView from "../../../Components/TableView/TableView";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import Filters from "../../../Components/Filters/Filters";
import { toast } from "react-toastify";

import { BASE_URL, API_ENDPOINT } from "../../../Config/Config";

function Users() {
  const [loading, setLoading] = useState(false);
  const [userData, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalPage, setTotalPages] = useState(1);
  const [isShowFilterContainer, setIsShowFilterContainer] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    limit: 100,
    orderBy: "DESC",
    firstname: "",
    lastname: "",
    state: "",
    city: "",
    dob_y: "",
    zip: "",
  });
  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    try {
      const url = BASE_URL + API_ENDPOINT.GET_CONSUMER_DATA;
      const body = {
        firstname: selectedFilters.firstname,
        lastname: selectedFilters.lastname,
        page: page + 1,
        limit: selectedFilters.limit,
        operator: selectedFilters.operator,
        dob_y: selectedFilters.dob_y,
        zip: selectedFilters.zip,
        state: selectedFilters.state,
        city: selectedFilters.city,
        orderBy: selectedFilters.orderBy,
      };
      setLoading(true);
      const response = await axios.post(url, body);
      setLoading(false);
      if (response.data.success) {
        setUsersData(response.data.data);
        setTotalPages(Math.floor(response.data.total_count / limit));
      } else {
        console.log(response.data.message);
        toast.dark("Hey 👋, No data found with given filter");
        setUsersData([]);
      }
    } catch (e) {
      setLoading(false);
      toast.dark(e);
      console.log(e);
    }
  };

  const onApplyFilters = async () => {
    try {
      console.log(selectedFilters);
      const url = BASE_URL + API_ENDPOINT.GET_CONSUMER_DATA;

      const body = {
        firstname: selectedFilters.firstname,
        lastname: selectedFilters.lastname,
        page: page + 1,
        limit: selectedFilters.limit,
        operator: selectedFilters.operator,
        dob_y: selectedFilters.dob_y,
        zip: selectedFilters.zip,
        state: selectedFilters.state,
        city: selectedFilters.city,
        orderBy: selectedFilters.orderBy,
      };
      setLimit(selectedFilters.limit);
      setLoading(true);
      const response = await axios.post(url, body);
      setLoading(false);

      if (response.data.success) {
        console.log(response.data.data);
        if (response.data.data.length === 0) {
          console.log("Successs with empty ");
          toast.dark("Hey 👋, No data found with given filter");
        } else {
          setIsShowFilterContainer(false);
        }
        setUsersData(response.data.data);
        setTotalPages(Math.floor(response.data.total_count / limit));
      } else {
        console.log(response.data.message);
        toast.dark("Hey 👋, No data found with given filter");
        setUsersData([]);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onExportCSV = async () => {
    // Convert the data to a CSV string

    const csv =
      "`id`,`firstname`,`lastname`,`city`,`state`,`phone`,`dob_y`,`dob_m`,`dob_d`,`age`,`language`,`religion`,`dnc`\n" +
      userData.map((item) => Object.values(item).join(",")).join("\n");

    // Create a Blob containing the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link for the CSV file
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "my-data.csv";
    a.click();
  };

  return (
    <section className="flex flex-col items-center pl-7 p-4 h-full w-full overflow-hidden">
      {isShowFilterContainer && (
        <section className="w-[70%] min-h-[16rem] bg-[#f0f0f0] p-4 border-2 rounded-md fixed z-10 right-8">
          <Filters
            onApplyFilters={onApplyFilters}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            onClose={() => setIsShowFilterContainer(false)}
          />
        </section>
      )}
      {loading && <Loader />}
      <div className="flex w-full items-center justify-between">
        <h2>User Data</h2>
        <div className="flex items-center justify-end gap-6">
          <button
            className="px-4 py-2  rounded-md border-[1px] border-primary text-primary "
            onClick={onExportCSV}
          >
            Export CSV
          </button>
          <button
            className="px-4 py-2  rounded-md border-[1px] border-primary bg-primary text-white "
            onClick={() => {
              setIsShowFilterContainer(!isShowFilterContainer);
            }}
          >
            Filters
          </button>
        </div>
      </div>

      <div className="mt-10 w-full">
        {userData.length > 0 ? (
          <TableView
            data={userData}
            setPage={setPage}
            setLimit={setLimit}
            totalPage={totalPage}
            limit={limit}
            page={page}
          />
        ) : (
          <div className="flex items-center justify-center ">No Data Found</div>
        )}
      </div>
    </section>
  );
}

export default Users;
