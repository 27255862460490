import React, { useState, useEffect } from "react";
import { AiFillCloseCircle, AiFillFolderOpen, AiOutlineCloudUpload } from "react-icons/ai";
import { IoIosDocument } from "react-icons/io";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL, API_ENDPOINT } from "../../../Config/Config";
import { toast } from "react-toastify";

function UploadData() {
  const [isFileValid, setIsFileValid] = useState(true);
  const inputRef = React.createRef();
  const [selectedFile, setSelecteFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFilesData();
  }, []);

  const getFilesData = async () => {
    try {
      setLoading(true);
      const url = BASE_URL + API_ENDPOINT.GET_FILES;
      const response = await axios.get(url);
      setLoading(false);
      if (response.data.success) {
        console.log(response.data.result);
        setFilesData(response.data.result.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".csv")) {
      setIsFileValid(true);
      setSelecteFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setIsFileValid(false);
    }
  };

  const onUploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("fileName", fileName);
      setLoading(true);
      const url = BASE_URL + API_ENDPOINT.UPLOAD_FILE;
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.msg === "Record Not found in the database") {
        toast.dark("Invalid content in the file");
      }
      console.log(response);
      setIsShowDialog(false);
      setSelecteFile(null);
      getFilesData();
      toast.success("File Uploaded Successfully");
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.dark(e);
    }
  };

  const onDownloadFile = async (fileName) => {
    try {
      const apiURl = BASE_URL + API_ENDPOINT.DOWNLOAD_FILE + fileName;
      const response = await axios.get(apiURl, {
        responseType: "blob", // Specify the response type as 'blob' to handle binary data
      });
      // Create a URL for the blob response
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (E) {
      setLoading(false);
    }
  };

  const onCloseDialog = () => {
    setIsShowDialog(false);
    setSelecteFile(null);
    setFileName("");
  };

  const onDeleteFile = async (fileName, id) => {
    try {
      const url = BASE_URL + API_ENDPOINT.DELETE_FILE + fileName + "/" + id;
      setLoading(true);
      const response = await axios.delete(url);
      setLoading(false);
      toast.success('File Deleted Succesfully');
      if (response.data.success) {
        await getFilesData();
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleTextClick = () => {
    inputRef.current.click();
  };
  return (
    <section className="h-[80vh] w-full scrollbar-none">
      {loading && <Loader />}
      {isShowDialog && (
        <div
          className="bg-white w-screen h-screen flex items-center justify-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 10,
          }}
        >
          <div className="flex p-4 flex-col bg-white cursor-pointer w-[40vw] h-[28vh] items-center rounded-lg ">
            <div
              className=" w-full flex items-end justify-end"
              onClick={onCloseDialog}
            >
              <AiFillCloseCircle size={24} />
            </div>

            <div className="flex bg-white cursor-pointer w-full h-full  items-center justify-center flex-col text-lg font-medium family-poppins text-primary">
              <div
                className="flex flex-col cursor-pointer items-center justify-center"
                onClick={handleTextClick}
              >
                <AiOutlineCloudUpload className="text-5xl text-primary" />
                {isFileValid ? (
                  selectedFile ? (
                    <p>{selectedFile.name}</p>
                  ) : (
                    <p>Upload .csv file Here</p>
                  )
                ) : (
                  "Please select a valid .csv file"
                )}
              </div>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={inputRef}
                style={{ display: "none" }}
              />
              {selectedFile && (
                <button
                  className="mt-8 px-16 py-2  rounded-md border-[1px] border-primary bg-primary text-white  "
                  onClick={onUploadFile}
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="w-full p-4 flex items-center justify-between">
        <h2>Search Bulk Data</h2>
        <button
          className="px-4 py-2  rounded-md border-[1px] border-primary bg-primary text-white "
          onClick={() => setIsShowDialog(true)}
        >
          Upload File
        </button>
      </div>

      <div>
        {filesData.length === 0 ? (
          <div className="w-full h-[68vh] flex flex-col items-center justify-center">
            <AiFillFolderOpen size={24} />
            No File Found
          </div>
        ) : (
          <div className="w-full h-[68vh] flex justify-start flex-wrap items-start gap-5 overflow-y-scroll scrollbar-none">
            {filesData.map((file) => (
              <div key={file.id} className="flex flex-col items-center justify-center w-[16rem] h-[14rem] bg-[#f0f0f0] rounded-lg">
                <IoIosDocument className="text-5xl" />
                <p className="text-overflow text-ellipsis w-[14rem] overflow-hidden whitespace-nowrap text-md mt-4 ">
                  {file.filename}
                </p>
                <div className="flex items-center justify-center gap-2 mt-3">
                  <button
                    onClick={() => onDownloadFile(file.filename)}
                    className="p-2 rounded-full hover:bg-white  hover:text-primary"
                  >
                    <DownloadIcon />
                  </button>
                  <button
                    onClick={() => onDeleteFile(file.filename, file.id)}
                    className="p-2 rounded-full hover:bg-white hover:text-red-500"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default UploadData;
