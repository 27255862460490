module.exports = {
  // BASE_URL: "http://localhost:3000/api/v1/selectpakistan",
  BASE_URL:
  "https://phpstack-888688-4012711.cloudwaysapps.com/api/v1/selectpakistan",
  API_ENDPOINT: {
    GET_FILES: "/consumer/getFiles",
    UPLOAD_FILE: "/consumer/getBulkData",
    DOWNLOAD_FILE: "/download/",
    DELETE_FILE: "/delete/",
    GET_CONSUMER_DATA: "/consumer/getConsumerData",
    CREATE_SALE: "/sale/create",
    GET_ALL_SALE: "/sale/getAllSale",

    USERS: {
      GET_USERS: "/user/all",
      SIGN_IN: "/user/signInUser",
      CREATE_USER: "/user/createUser",
      UPDATE_USER: "/users/updateUser",
      DELETE_USER: "/users/deleteUser",
    },
  },
};
