import React from "react";
import { withRouter } from "react-router-dom";


function Dashboard() {
  return (
    <section className=" h-[80vh] overflow-y-scroll w-full p-4">
      <div className="flex items-center justify-between gap-3 flex-wrap w-full">
        <div className="w-[18rem] h-[8rem] rounded-md bg-[#f1f6ff] flex flex-col items-center justify-center">
          <label>Sales</label>
          <p>400</p>
        </div>
        <div className="w-[18rem] h-[8rem] rounded-md bg-[#f1f6ff] flex flex-col items-center justify-center">
          <label>Calls Per Day</label>
          <p>300</p>
        </div>
        <div className="w-[18rem] h-[8rem] rounded-md bg-[#f1f6ff] flex flex-col items-center justify-center">
          <label>Average Sale</label>
          <p>2.3</p>
        </div>
        <div className="w-[18rem] h-[8rem] rounded-md bg-[#f1f6ff] flex flex-col items-center justify-center">
          <label>Accounts</label>
          <p>$1,000</p>
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 flex-wrap w-full mt-4">
        <div className="w-[60%] h-[30rem] rounded-md bg-[#ffffff] border-[1px] ">
          <div className=" border-b-[1px] border-[#b8b8b8] w-full p-4">
            Deployment Acitivity
          </div>
          <div className=" p-4">
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Dashboard);
